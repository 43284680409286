import React, { useEffect } from 'react'
import { graphql, useStaticQuery, navigate } from 'gatsby'
import { hierarchicalMenu, Link } from '../../plugins/gatsby-plugin-atollon'
import FormComponent from './form'
import AnimationButton from "./structured-text/blocks/animation-button";

const Footer = ({brand}) => {
  const languageKey = 'au'

  const staticData = useStaticQuery(graphql`
     query DesktopFooterData {
      au: allDatoCmsFooterMenuItem {
        nodes {
          id
          treeParent {
            id
          }
          title
          position
          link {
            ...LinkWithoutTitleProps
          }
          hideForLocale
          boneCategories {
            title
          }
          edlynCategories {
            title
          }
          greenwheatCategories {
            title
          }
          woodsCategories {
            title
          }
        }
      }
      newsletterForm: datoCmsForm(title: {eq: "Subscribe"}) {
        ...FormProps
      }
    }
  `)
  const menuForLang = staticData[languageKey].nodes
  const menu = hierarchicalMenu(menuForLang)
  let categories, brandSlug
  switch (brand) {
    case 'bone':
      categories = menu.childMenu[3].boneCategories
      brandSlug = 'bone-roasters'
      break
    case 'greenwheat':
      categories = menu.childMenu[3].greenwheatCategories
      brandSlug = 'greenwheat-freekeh'
      break
    case 'woods':
      categories = menu.childMenu[3].woodsCategories
      brandSlug = brand
      break
    default:
      categories = menu.childMenu[3].edlynCategories
      brandSlug = brand
  }

  const handleParams = (title) => {
    navigate(`/${brandSlug || 'edlyn'}/our-products?category=${title.split(' ').join('-').toLocaleLowerCase()}`)
  }


  let itemsArray = []
  for (let i = 0; i < menu.childMenu.length; i++) {
    itemsArray.push(false)
  }

  let footerClass = 'footer'
  if ( brand ) footerClass += ` ${brand}`

  return (
    <footer className={footerClass}>
      <div className='footer__top'>
        <div className='footer__inner'>
          <div className='footer__left'>
            <div className='footer__subscribe'>
              <p>Stay informed of new products, projects and people shaping the future of food service in Australia.</p>
              <FormComponent form={ staticData.newsletterForm }/>
            </div>
            <div className='footer__contacts'>
              <div className='footer__contact'>
                <p><strong>Customer Service</strong></p>
                <p>
                  <strong>P</strong> <Link to='tel:1300661908'>1300 661 908</Link><br />
                  <strong>E</strong> <Link to='mailto:sales@edlyn.com.au'>sales@edlyn.com.au</Link>
                </p>
              </div>
              <div className='footer__contact'>
                <p><strong>Edlyn Foods Pty Ltd</strong></p>
                <p>
                  13 Ricky Way Epping<br />
                  Victoria Australia 3076
                </p>
              </div>
              <div className='footer__contact'>
                <p><strong>P</strong> <Link to='tel:+61384010000'>03 8401 0000</Link></p>
              </div>
            </div>
          </div>
          <div className='footer__right'>
            <nav className='footer__nav'>
              { menu.childMenu &&
                <ul className='footer__nav-menu'>
                  { menu.childMenu.map((item, index) => (
                    <li key={ index }>
                      <h4>{ item.title }</h4>
                      { item.childMenu?.length > 0 &&
                        <ul>
                          { item.childMenu.map((sub, i) => (
                            <li key={ i }>
                              <Link  {...sub.link}>{ sub.title }</Link>
                            </li>
                          )) }
                        </ul>
                      }
                      { item.title === 'Products' && categories?.length > 0 &&
                        <ul>
                          { categories.map((cat, i) => (
                            <li key={ i }>
                              <button onClick={() => handleParams(cat.title)}>{ cat.title }</button>
                            </li>
                          )) }
                        </ul>
                      }
                    </li>
                  )) }
                </ul>
              }
            </nav>
          </div>
        </div>
      </div>
      <div className='footer__bottom'>
        <div className='footer__inner'>
          <div className='footer__left'>
            <ul>
              <li><Link target='__blank' to='https://www.instagram.com/edlyn_foods/?hl=en'>Instagram</Link></li>
              <li><Link target='__blank' to='https://www.facebook.com/edlynfoods/'>Facebook</Link></li>
              <li><Link target='__blank' to='https://www.youtube.com/user/edlynfoods/videos'>YouTube</Link></li>
            </ul>
          </div>
          <div className='footer__right'>
            <p>
              © {new Date().getFullYear() } Edlyn Foods Pty Ltd.<br />
              Design by <Link to='https://atollon.com.au?utm_source=edlyn&utm_medium=website&utm_campaign=referrer&utm_term=services' target='_blank' rel='noopener noreferrer'>Atollon</Link>
            </p>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
